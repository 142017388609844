import './App.css';
import Scene from './components/Scene';


function App() {
  return (
    <Scene />
  );
}

export default App;
